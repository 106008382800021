import { FnPaginationProps } from './model';

const paginate = ({ total, current, size }: FnPaginationProps) => {
  // calculate total pages
  const pages = Math.ceil(total / size);
  let currentPage = current;

  // ensure current page isn't out of range
  if (currentPage < 1) {
    currentPage = 1;
  } else if (currentPage > pages) {
    currentPage = pages;
  }

  // calculate start and end item indexes
  const start = (currentPage - 1) * size;
  const end = Math.min(start + size - 1, total - 1);

  // return object with all pager properties required by the view
  return {
    start,
    end,
  };
};

export default paginate;
