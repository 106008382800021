import React, { FC, useMemo } from 'react';
import { graphql, PageProps } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';

import bodyRenderer from 'utils/bodyRenderer';
import Banner from 'components/Banner';
import Layout from 'components/Layout';
import Callout from 'components/common/Callout';
import DosageInfo from 'components/DosageInfo';
import SimpleSection from 'components/PodcastsPageComponents/SimpleSection';
import VideoPreview from 'components/VideoPreview';
import ArticlePreview from 'components/ArticlePreview';
import ControlledCarousel from 'components/ControlledCarousel';
import SimplePagination from 'components/SimplePagination';
import SymptomsIllustrations from 'components/SymptomsIllustrations';
import RelatedProducts from 'components/RelatedProducts';
import RecentlyViewedProducts from 'components/RecentlyViewedProducts/RecentlyViewedProducts';
import BreadCrumbs from 'components/BreadCrumbs';
import PageBreaker from 'components/PageBreaker';
import VideoBlocksWithCarouselSection from 'components/VideoBlocksWithCarouselSection';

import { parseBanner } from 'components/Banner/parsers';
import parseVideo from 'components/common/Video/parsers';
import { parseNFButton } from 'components/common/NFButton/parsers';
import parseCalloutSection from 'templates/AdvancedChildrenHubArticlePage/parsers';
import { parseSymptomsList } from 'components/SymptomsIllustrations/parsers';
import parseRecentlyViewedProducts from 'components/RecentlyViewedProducts/parsers';

import './AdvancedChildrenHubListingPage.scss';

const videosResponsiveObj = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 0 },
    items: 1,
  },
};

interface AdvancedChildrenHubListingPageProps extends PageProps {
  data: AdvancedChildrenHubListingTypes.IPage;
  pageContext: {
    breadcrumbs: UmbracoTypes.IBreadCrumb[];
    topProducts: string[];
  };
}

const bodyComponents: AppPageDataTypes.TBodyElements = {
  'Dosage Info Section': ({
    dosageInfoSection: [
      {
        properties: { title, subtitle, isDosageInfoTwoColumn, ctaButton },
      },
    ],
  }) => (
    <DosageInfo
      key="Dosage Info Section"
      title={title}
      subtitle={subtitle}
      ctaButton={ctaButton}
      isDosageInfoTwoColumn={isDosageInfoTwoColumn}
    />
  ),
  'Callout Section': ({
    calloutBlock,
    tagsListBlock,
    featuredItemsSection,
    ariaLabelPrev,
    ariaLabelNext,
  }) => {
    const {
      callout: { title, description, color },
      tagsLists,
      featuredArticles,
      featuredArticlesCTAButton,
    } = parseCalloutSection({
      calloutBlock,
      tagsListBlock,
      featuredItemsSection,
    });

    return (
      <Callout
        key="Callout-Section"
        title={title}
        description={description}
        calloutColor={color}
        tagsLists={tagsLists}
        featuredArticles={featuredArticles}
        featuredArticlesCTAButton={featuredArticlesCTAButton}
        settings={{ carouselControls: { ariaLabelNext, ariaLabelPrev } }}
      />
    );
  },
  'Featured Items Section': ({ featuredItemsSection, ariaLabelPrev, ariaLabelNext }) => {
    const {
      featuredItemsSectionTitle,
      featuredItemsSectionCTA,
      featuredVideos,
    } = featuredItemsSection[0].properties;

    const parsedCTASection = featuredItemsSectionCTA?.length
      ? { ...parseNFButton(featuredItemsSectionCTA[0].properties) }
      : undefined;

    const parsedVideos = featuredVideos.map(({ url, videoBlock }) => ({
      url,
      ...parseVideo(videoBlock),
    }));

    return (
      <SimpleSection
        key="Featured-Items-Section"
        sectionTitle={featuredItemsSectionTitle}
        sectionClassname="video-previews-carousel"
        {...(parsedCTASection ? { sectionCTA: parsedCTASection } : {})}
      >
        <ControlledCarousel
          bgGradientColor="white"
          responsiveObj={videosResponsiveObj}
          carouselControls={{
            ariaLabelPrev,
            ariaLabelNext,
          }}
        >
          {parsedVideos.map(
            ({
              url,
              videoTitle,
              videoOverlappingLabel,
              btnPlay,
              previewImg,
              videoBottomOverlappingTitle,
            }) => (
              <div key={videoTitle} className="video-preview-container">
                <Container>
                  <Row>
                    <Col sm={12}>
                      <VideoPreview
                        url={url}
                        videoLabel={videoOverlappingLabel}
                        playIcon={btnPlay}
                        previewImg={previewImg}
                        bottomOverlappingTitle={videoBottomOverlappingTitle}
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
            )
          )}
        </ControlledCarousel>
      </SimpleSection>
    );
  },
  'All Articles By Listing Tag': ({ allArticlesByListingTag }) => {
    const { articlesByListingTag, sectionTitle } = allArticlesByListingTag[0].properties;

    return (
      <SimpleSection
        key="All-Articles-By-Listing-Tag"
        sectionTitle={sectionTitle}
        sectionClassname="articles-by-listing-tag-section"
      >
        <SimplePagination
          items={articlesByListingTag.map((article) => (
            <article key={article.title} className="articles-by-listing-tag__card">
              <ArticlePreview {...article} />
            </article>
          ))}
          numberItemsPerPage={6}
          cardsContainerClassname="articles-by-listing-tag__container"
          paginationContainerClassname="articles-by-listing-tag__pagination"
        />
      </SimpleSection>
    );
  },
  'Symptoms List': ({ symptomsListItems, symptomsHeadingGroup, symptomsAnchor }) => {
    const symptomsCardsListProps = parseSymptomsList({
      symptomsListItems,
      symptomsHeadingGroup,
      symptomsAnchor,
    });

    return <SymptomsIllustrations key="Symptoms-List" {...symptomsCardsListProps} />;
  },
  'Video Blocks With Carousel Section': ({
    videoBlocksWithCarouselSection: [
      {
        properties: { title, videoList, ctaButton },
      },
    ],
    ariaLabelPrev,
    ariaLabelNext,
  }) => {
    return (
      <Container>
        <Row>
          <Col lg={{ span: 10, offset: 1 }} sm={12}>
            <VideoBlocksWithCarouselSection
              key="Video Blocks With Carousel Section"
              title={title}
              videoList={videoList}
              ctaButton={ctaButton}
              carouselControls={{
                ariaLabelPrev,
                ariaLabelNext,
              }}
            />
          </Col>
        </Row>
      </Container>
    );
  },
};

const AdvancedChildrenHubListingPage: FC<AdvancedChildrenHubListingPageProps> = ({
  pageContext: { breadcrumbs, topProducts: topProductsLinks },
  data: {
    allAdvancedChildrenHubListingPage: {
      nodes: [
        {
          isMasterPage,
          description,
          banner,
          body,
          topProductsConfig,
          viewedProductsConfig,
          seoMetaTitle,
          seoMetaDescription,
          seoMetaKeywords,
          seoExternalHreflangs,
          lang,
          pageName,
        },
      ],
    },
    topProducts,
    carouselControls,
    header,
    footer,
    siteSettings,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    brandSettings,
    legalPopup,
    pageLangs: { nodes: langs },
  },
}) => {
  const viewedProducts = useMemo(
    () =>
      viewedProductsConfig?.length ? (
        <RecentlyViewedProducts
          {...parseRecentlyViewedProducts(
            viewedProductsConfig[0].properties,
            { carouselControls },
            lang
          )}
          pageName={pageName}
        />
      ) : null,
    [viewedProductsConfig]
  );

  const ourTopProducts = useMemo(() => {
    return topProductsConfig?.length && topProducts?.nodes?.length ? (
      <RelatedProducts
        title={topProductsConfig?.[0]?.properties?.relatedProductsTitle}
        showBtn={topProductsConfig?.[0]?.properties?.shopBtnsShow === '1'}
        btn={
          topProductsConfig?.[0]?.properties?.sectionCtaBtn?.length
            ? parseNFButton(topProductsConfig?.[0]?.properties?.sectionCtaBtn?.[0]?.properties)
            : undefined
        }
        productsLinks={topProductsLinks}
        products={topProducts?.nodes}
        carouselControls={carouselControls}
        listingName={pageName}
      />
    ) : null;
  }, [topProductsConfig, topProducts]);

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
      }}
      header={header}
      footer={footer}
      siteSettings={siteSettings}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      legalPopup={legalPopup}
      langSettings={{
        currentLang: lang,
        langs,
      }}
    >
      <div>
        {banner[0].properties.pageBannerPageBreaker === '1' ? (
          <PageBreaker
            img={banner[0].properties.pageBannerImage!}
            imgAlt={banner[0].properties?.pageBannerImageAlt ?? ''}
          />
        ) : (
          <Banner {...parseBanner(banner[0].properties)} />
        )}

        <div className="advanced-children-hub-listing-page">
          {!isMasterPage ? (
            <Container>
              <Row>
                <Col lg={{ span: 10, offset: 1 }} sm={12}>
                  <BreadCrumbs data={breadcrumbs} />
                </Col>
              </Row>
            </Container>
          ) : null}

          {description ? (
            <SimpleSection sectionSubtitle={description} sectionClassname="top-info" />
          ) : null}
          {bodyRenderer(body, bodyComponents, carouselControls)}
          {viewedProducts}
          {ourTopProducts}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query(
    $lang: String!
    $link: String!
    $topProducts: [String]
    $pageIdRegex: String
    $pageBannerImageSize: Int = 2000
  ) {
    allAdvancedChildrenHubListingPage(filter: { lang: { eq: $lang }, link: { eq: $link } }) {
      nodes {
        lang
        pageName
        isMasterPage
        description
        banner {
          properties {
            ...FragmentBanner
          }
        }
        body {
          structure
          properties {
            ...FragmentDosageInfoSection
            ...FragmentSymptomsIllustrations
            ...FragmentAdvancedChildrenHubCalloutSection
            ...FragmentVideoBlocksWithCarouselSection
            featuredItemsSection {
              properties {
                ...FragmentFeaturedItemsSection
              }
            }
            allArticlesByListingTag {
              properties {
                ...FragmentSimpleSection
                articlesByListingTag {
                  ...FragmentArticlePreviewCard
                }
              }
            }
          }
        }
        topProductsConfig {
          properties {
            ...FragmentRelatedProductsConfig
          }
        }
        viewedProductsConfig {
          properties {
            ...FragmentRecentlyViewedProducts
          }
        }
        ...FragmentSeo
      }
    }
    topProducts: allUmbracoProduct(
      filter: { link: { in: $topProducts }, lang: { eq: $lang } }
      limit: 10
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
    carouselControls: siteSettings(lang: { eq: $lang }) {
      ariaLabelNext
      ariaLabelPrev
    }
    pageLangs: allAdvancedChildrenHubListingPage(filter: { umbracoId: { regex: $pageIdRegex } }) {
      nodes {
        link
        lang
      }
    }
    ...FragmentCommonCompositions
  }
`;

export default AdvancedChildrenHubListingPage;
