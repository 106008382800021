import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';

import { parseNFButton } from 'components/common/NFButton/parsers';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import NFButton from 'components/common/NFButton';

import { IDosageInfoComponentProps } from './model';

import './DosageInfo.scss';

const DosageInfo: FC<IDosageInfoComponentProps> = ({
  title,
  subtitle,
  ctaButton,
  isDosageInfoTwoColumn,
}) => {
  return (
    <div className="dosage-info-section">
      <Container fluid>
        <Row>
          <Col
            lg={isDosageInfoTwoColumn ? { span: 5, offset: 1 } : { span: 10, offset: 1 }}
            sm={12}
          >
            <h2 className="title">{title}</h2>
            <DangerouslySetInnerHtml html={subtitle} className="subtitle" />
          </Col>
          <Col lg={isDosageInfoTwoColumn ? { span: 5 } : { span: 12 }} sm={12}>
            <NFButton {...parseNFButton(ctaButton[0].properties)} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentDosageInfoSection on IDosageInfoSection {
    dosageInfoSection {
      properties {
        title
        subtitle
        isDosageInfoTwoColumn
        ctaButton {
          properties {
            ...FragmentNFButton
          }
        }
      }
    }
  }
`;

export default DosageInfo;
