import { useEffect, useState } from 'react';
import { IUsePagination, UsePaginationProps } from './model';
import paginate from './utils';

const usePagination = ({ items, size }: UsePaginationProps): IUsePagination => {
  const [totalPages, setTotalPages] = useState<number>(Math.ceil(items.length / size));
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsToDisplay, setItemsToDisplay] = useState<any[]>([]);

  useEffect(() => {
    setTotalPages(Math.ceil(items.length / size));
    const { start, end } = paginate({
      current: currentPage,
      total: items.length,
      size,
    });
    const display = items.slice(start, end + 1);

    setItemsToDisplay(display);
  }, [currentPage, items, size]);

  return {
    currentPage,
    totalPages,
    itemsToDisplay,
    setCurrentIndex: (num: number) => setCurrentPage(num),
  };
};

export default usePagination;
