import React, { FC, useCallback, useMemo } from 'react';

import usePagination from 'hooks/usePagination';
import PaginationItem from 'components/Pagination/PaginationItem';

import { ISimplePaginationComponentProps } from './model';

const SimplePagination: FC<ISimplePaginationComponentProps> = ({
  items,
  numberItemsPerPage,
  cardsContainerClassname,
  paginationContainerClassname,
}) => {
  const { currentPage, totalPages, itemsToDisplay, setCurrentIndex } = usePagination({
    items,
    size: numberItemsPerPage,
  });

  const paginationIndexes = useMemo(() => Array.from({ length: totalPages }, (_, i) => i + 1), [
    totalPages,
  ]);

  const onChangePaginationIndex = useCallback(
    (current: number) => () => {
      setCurrentIndex(current);
    },
    []
  );

  return (
    <>
      <div className={cardsContainerClassname}>{itemsToDisplay}</div>

      {totalPages > 1 ? (
        <ul className={`nf-pagination ${paginationContainerClassname || ''}`}>
          {paginationIndexes.map((i) => (
            <PaginationItem
              key={i}
              num={i}
              active={currentPage}
              handlePagingClick={onChangePaginationIndex(i)}
              isNumerationStartFromZero={false}
            />
          ))}
        </ul>
      ) : null}
    </>
  );
};

export default SimplePagination;
